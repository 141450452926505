
<template>
  <el-row>
    <el-col :span="(context === 'static' ? 24 : 7)">
      <el-table
        ref="tnmtable"
        :data="tableData"
        :default-sort = "{prop: 'date', order: 'ascending'}"
        style="width: 100%"
        :highlight-current-row="(context !== 'static')"
        @row-click="onRowClick"
        :show-header="(context !== 'static')"
        stripe>
        <el-table-column
          prop="date"
          label="Datum"
          width="100"
          sortable>
          <template #default="scope">
            <span v-if="scope.row.date">{{ $filters.germanDate(scope.row.date) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="tnm"
          label="TNM">
        </el-table-column>
        <el-table-column width="30">
          <template #default="scope">
            <i v-if="scope.row.is_cancerregistry" class="icon el-icon-star-on text-danger" style="font-size:1.2em;" @click="scope.row.is_cancerregistry = false"></i>
            <i v-if="!scope.row.is_cancerregistry" class="icon el-icon-star-off" @click="scope.row.is_cancerregistry = true; onCancerregistryChange(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column
          v-if="(context !== 'static')"
          width="70">
          <template #default="scope">
            <div class="text-right">

              <el-popconfirm
                title="Sie möchten diese TNM-Daten löschen?"
                confirmButtonText='Ja, löschen'
                confirmButtonType="danger"
                cancelButtonText='Nein'
                iconColor="red"
                @confirm="onRowDeleteConfirmed(scope.row)">
                <template #reference>
                  <el-button size="mini" type="warning" icon="el-icon-delete"></el-button>
                </template>
              </el-popconfirm>

            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-button v-if="(context !== 'static')" class="m-3" type="warning" @click="onClickNewTNM">TNM hinzufügen</el-button>

    </el-col>
    <el-col :span="16" :offset="1" v-if="context === 'diagnosis'">
      <TNMExtract v-if="selectedTNM" v-model="selectedTNM" :diagnosis_id="diagnosis_id" :context="context" :icdo3h="icdo3h" :icdo3t="icdo3t" @cancerregistry-change="onCancerregistryChange"></TNMExtract>
    </el-col>
  </el-row>
</template>

<script>
import TNMExtract from "./TNMExtract.vue";
import moment from "moment";

export default {
  name: "TNM",
  components: {TNMExtract},
  props: ['modelValue', 'diagnosis_id', 'context', 'hide-header', 'icdo3t', 'icdo3h'],
  data() {
    return {
      tableData: [],
      selectedTNM: null,
    }
  },
  computed: {
  },
  mounted: async function () {
    this.init()
  },
  watch: {
    'modelValue': function() {
      this.init()
    },
    'tableData': {
      handler(val) {
        this.$emit('update:modelValue', val)
      },
      deep: true
    }
  },
  methods: {
    init() {
      this.tableData = this.modelValue ? this.modelValue : []
      if(this.tableData && this.tableData.length === 1) {
        this.onRowClick(this.tableData[0])
      }
    },
    onClickNewTNM() {
      if (!this.tableData) this.tableData = []
      this.tableData.push({
        id: 0,
        date: moment().format('YYYY-MM-DD'),
        tnm: ''
      })
      this.onRowClick(this.tableData[this.tableData.length - 1])
    },
    onRowClick(row) {
      if(this.context === 'static') return
      this.selectedTNM = row
      this.$refs.tnmtable.setCurrentRow(row)
    },
    async onRowDeleteConfirmed(deleteRow) {
      this.selectedTNM = null
      let deleteIndex = 0
      for(const index in this.tableData) {
        if(this.tableData[index].id === deleteRow.id && this.tableData[index].tnm === deleteRow.tnm) {
          deleteIndex = index
          break
        }
      }

      this.tableData.splice(deleteIndex, 1);
      if(this.tableData && this.tableData.length === 1) {
        this.onRowClick(this.tableData[0])
      }

    },
    onCancerregistryChange(tnmObject) {
      if(tnmObject.is_cancerregistry === true) {
        // a TNM was selected for Cancer Registry, deselect all other
        for(const index in this.tableData) {
          if(JSON.stringify(tnmObject) !== JSON.stringify(this.tableData[index])) this.tableData[index].is_cancerregistry = false
        }
      }

    }
  }
}
</script>

<style scoped>

</style>
